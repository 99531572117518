﻿//Css Dependencies: Bootstrap 
import 'bootstrap/dist/css/bootstrap.css';


//Global CSS Imports:
import '../css/site.css';
import '../css/layout.css';

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'
